<template>
	<div id="getting-to-mongolia">
		<div class="getting-left">
			<p class="getting-title" data-aos="zoom-out" data-aos-delay="300">
				Getting to Mongolia
			</p>
			<p class="description" data-aos="zoom-out" data-aos-delay="600">
				Direct flights are available from countries such as China, Russia, Germany, Korea, Hong Kong, and Turkey. You can also visit Mongolia by train via Trans-Mongolian Line, which is part of the Trans-Siberian Railway. If you’re looking for an adventure and depending on your nationality, you can even drive to Mongolia from Russia or China.
			</p>

			<ul class="menu-link">
				<li class="text-center" data-aos="zoom-out" data-aos-delay="900">
					<router-link :to="{ name: 'ground-transport' }">
						<div class="mb-2">
							<img :src="groundTransport" alt="groundTransport">
						</div>
						<span>Overland</span>
					</router-link>
				</li>
				<li class="text-center" data-aos="zoom-out" data-aos-delay="1200">
					<router-link :to="{ name: 'air-transport' }">
						<div class="mb-2">
							<img :src="airTransport" alt="airTransport">
						</div>
						<span>By Plane</span>
					</router-link>
				</li>
			</ul>
		</div>
		<div class="getting-right">
			<div class="box">
				<p class="title-1" data-aos="zoom-out" data-aos-delay="300">Visa</p>
				<p class="description" data-aos="zoom-out" data-aos-delay="600">All visitors to Mongolia require a visa unless they come from one of the visa exempt countries. Visitors must hold passports that are valid for at least 6 months from the date of arrival.</p>
				<a href="http://consul.mn/eng/01visae.php" target="_blank" class="btn btn-visa " data-aos="zoom-out" data-aos-delay="900">Visa Info.</a>
				<a href="http://consul.mn/eng/02visae.php" target="_blank" class="btn btn-visa ml-2 mr-2" data-aos="zoom-out" data-aos-delay="1200">Lists of Visa </a>
				<button type="button" class="btn btn-visa" @click="fnShowEmbassy" data-aos="zoom-out" data-aos-delay="1500">Lists of Embassy</button>
			</div>
			<div class="box">
				<p class="title-2" data-aos="zoom-out" data-aos-delay="1800">Staying longer</p>
				<p class="description" data-aos="zoom-out" data-aos-delay="2100">If your intended stay in Mongolia is beyond that of the period allowed under the visa exemption arrangements, you must apply for an appropriate type of visa before you travel to Mongolia.</p>
			</div>
		</div>

		<Embassy v-if="showEmbassy == true" @fnCloseEmbassy="fnCloseEmbassy" />
	</div>
</template>

<script>
import('aos').then(AOS => AOS.init());
import groundTransport from '../../assets/img/ground-transport.png'
import airTransport from '../../assets/img/air-transport.png'
import Embassy from './EmbassyList';
export default {
	name: "GettingToMongolia",
	components: {
		Embassy
	},
	data() {
		return {
			groundTransport : groundTransport,
			airTransport : airTransport,
			showEmbassy : false
		}
	},
	methods: {
		fnShowEmbassy() {
			this.showEmbassy = true;
		},
		fnCloseEmbassy() {
			this.showEmbassy = false;
		}
	}
};
</script>

<style lang="scss" scoped>
	#getting-to-mongolia {
		width: 100%;
		height: 100%;
		display: flex;
		.getting-left {
			width: 50%;
			background-color: #E13036;
			color: #FFFFFF;
			padding: 50px;
			.getting-title {
				font-size: 38px;
				font-weight: bold;
			}

			.menu-link {
				padding: 10% 0 0;
				list-style: none;
				display: flex;

				li{
					flex-wrap: wrap;
					width: 50%;

					a {
						color: #FFFFFF;
					}
				}
			}
		}

		.getting-right {
			width: calc(50% - 40px);
			padding: 50px;
			.box {
				margin-bottom: 30px;

				.title-1,.title-2 {
					margin: 0;
				}

				.title-1 {
					font-size: 22px;
					font-weight: bold;
				}

				.title-2 {
					font-size: 20px;
				}

				.description {
					font-size: 12px;
				}

				.btn-visa {
					font-size: 12px;
					border: 1px #2C2C2C solid;
					border-radius: 0px; 
				}
			}
		}

		@media only screen and (min-width: 1920px) {
			.getting-left {
				padding: 150px 75px 0;
				.getting-title {
					font-size: 50px;
					font-weight: bold;
				}

				.menu-link {
					padding: 175px 0 0;
				}
			}

			.getting-right {
				padding: 150px 70px 0;
				.box {
					margin-bottom: 60px;

					.title-1 {
						font-size: 30px;
					}

					.title-2 {
						font-size: 22px;
					}

					.description {
						font-size: 15px;
					}

					.btn-visa {
						font-size: 17px;
						border: 1px #2C2C2C solid;
						border-radius: 0px; 
					}
				}
			}
		}
	}
</style>