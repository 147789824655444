<template>
    <div id="Embassy">
        <img :src="close" class="close" alt="close" @click="$emit('fnCloseEmbassy', false)">
        <div class="list">
            <div class="box" v-for="(item, index) in list" :key="index">
                <p class="title-box">{{ item.name }}</p>
                <div class="detail-address">
                    <p class="title">{{ item.title }}</p>
                    <div class="description" v-html="item.description"></div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import close from '../../assets/img/close-embassy.png';

export default {
    name : 'Embassy',
    data() {
        return {
            close : close,
            list : [
                {
                    name : 'Australia',
                    title : 'Embassy in Canberra, ACT',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        29 Cobbadan Street, O’Malley<br/>
                                        Canberra, ACT 2606 Commonwealth<br/>
                                        of Australia<br/>Tel: 61-2-628 629 47<br/>
                                        E: mngemb@bigpond.com<br/>
                                        www.mongolianembassy.org.au
                                    `
                },
                {
                    name : 'Austria',
                    title : 'Embassy in Vienna',
                    description :   `
                                        Botschaft der Mongolei Fasangartengasse 45 1130 
                                        Wien Österreich (Austria) 
                                        Tel: 43-1-5352807-0
                                        E: office@embassymon.at 
                                        www.embassymon.at
                                    `
                },
                {
                    name : 'Belgium',
                    title : 'Embassy in Brussels',
                    description :   `
                                        Ambassade de Mongolie<br/>
                                        Avenue Besme 18, 1190, Forest Bruxelles<br/>
                                        Belgique (Belgium)<br/>
                                        Tel: 32-2-344-6974<br/>
                                        brussels.mn.embassy@telenet.be<br/>
                                        www.embassyofmongolia.be
                                    `
                },
                {
                    name : 'Bulgaria',
                    title : 'Embassy in Sofia',
                    description :   `
                                        Sofia 1113, ul.Frederic Jolio Curie 52,<br/>
                                        Embassy of Mongolia, Bulgaria<br/>
                                        Tel: 359-2-8659012<br/>
                                        359-2-8665929<br/>
                                        359-2-8659012<br/>
                                        E: mongemb@gmail.com
                                    `
                },
                {
                    name : 'Canada',
                    title : 'Embassy in Ottawa',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        151 Slater Street,Suite 503 Ottawa,<br/>
                                        Canada ON K1P 5H3<br/>
                                        Tel: 1-613-569-3830<br/>
                                        1- 613 698 9829<br/>
                                        E: mail@mongolembassy.org<br/>
                                        consul@mongolembassy.org<br/>
                                        www.mongolembassy.org
                                    `
                },
                {
                    name : 'China',
                    title : 'Embassy in Beijing',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        Tel: 86-10-6532-6512<br/>
                                        86-10-6532-6513<br/>
                                        86-10-6532-1810<br/>
                                        86-10-6532-1203<br/>
                                        E: mail@mongolembassychina.org<br/>
                                        www.mongolembassychina.org
                                    `
                },
                {
                    name : 'Cuba',
                    title : 'Embassy in Havana',
                    description :   `
                                        Embajada de Mongolia<br/>
                                        Calle 66 No.505, esquina a 5ta. A,<br/>
                                        Miramar, La Habana. Cuba<br/>
                                        Tel: 53-7-2042763<br/>
                                        E: embahavana@ceniai.inf.cu<br/>
                                        havana@mfat.gov.mn
                                    `
                },
                {
                    name : 'Czech Republic',
                    title : 'Embassy in Prague',
                    description :   `
                                        Mongolska Na Marne 5, Praha 6,<br/>
                                        160 00 Ceska Republika,<br/>
                                        Czech Republic<br/>
                                        Tel: 420 224311198<br/>
                                        420 224325760,<br/>
                                        420 233326976<br/>
                                        E: monemb@bohem-net.cz<br/>
                                        prague@emongolia.eu
                                    `
                },
                {
                    name : 'Egypt',
                    title : 'Embassy in Cairo',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        No.14, str 152 , MAADI, Cairo Egypt <br/>
                                        Tel: 202-23586012<br/>
                                        202-23591670<br/>
                                        E: monemby@link.net
                                    `
                },
                {
                    name : 'England',
                    title : 'Embassy in London',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        7-8 Kensington Court, London, W8 5DL United Kingdom <br/>
                                        Tel:(44) 207-937-0150, 7252 <br/>
                                        office@embassyofmongolia.co.uk<br/>
                                        www.embassyofmongolia.co.uk
                                    `
                },
                {
                    name : 'France',
                    title : 'Embassy in Paris',
                    description :   `
                                        Ambassade de Mongolie 5, <br/>
                                        Avenue Robert Schuman 92100 <br/>
                                        Boulogne-Billancourt FRANCE <br/>
                                        Tel: 33-1-46 05 28 12, <br/>
                                        33-1-46 05 23 18 (консул) <br/>
                                        E: info@ambassademongolie.fr <br/>
                                        www.ambassademongolie.fr
                                    `
                },
                {
                    name : 'Germany',
                    title : 'Embassy in Berlin',
                    description :   `
                                        Botschaft der Mongolei <br/>
                                        Dietzgen-Str.31, 13156 Berlin BR Deutschland (German) <br/>
                                        Tel: 49-30-474806-0 <br/>
                                        49-30-474806-14<br/>
                                        49-30-474806-13 (mobile) 98683215 <br/>
                                        E: 520025070730-0001@t-online.de Mongolbot@aol.com<br/>
                                        www.botschaft-mongolei.de
                                    `
                },
                {
                    name : 'Hungary',
                    title : 'Embassy in Budapest',
                    description :   `
                                        Hungary 1022 Budapest, <br/>
                                        Bogar Utca 14/c, Hungary Mongol Nagykovetseg <br/>
                                        Tel: 36-1-2124579<br/>
                                        36-1-3268558 <br/>
                                        E: mongolemb@t-online.hu
                                    `
                },
                {
                    name : 'India',
                    title : 'Embassy in New Delhi',
                    description :   `
                                        Embassy of Mongolia <br/>
                                        34, Archbishop Macarios Marg,<br/>
                                        New Delhi 110003<br/>
                                        Tel: 91-11-24631728, 24617989 <br/>
                                        E: mongemb@bol.net.in<br/>
                                        www.mongolindia.com
                                    `
                },
                {
                    name : 'Japan',
                    title : 'Embassy in Tokyo',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        21-4 Kamiyama-Cho, Shibuya- Ku Tokyo 150-0047 Japan<br/> 
                                        Tel: 81-3-3469-2088<br/>
                                        81-3-3469-2195<br/>
                                        81-3-3469-2179<br/>
                                        81-3-3469-2162<br/>
                                        E: embmong@gol.com embmong.com
                                    `
                },
                {
                    name : 'Kazakhstan',
                    title : 'Embassy in Almaty',
                    description :   `
                                        Embassy of Mongolia, Kazakhstan<br/>
                                        Almaty ul. Musabaeva D 1<br/>
                                        Tel: 7-7272-69 35 36,<br/>
                                        7-7272-693570,<br/>
                                        7-327-2293790,<br/>
                                        7-7272-551278<br/>
                                        Fax:7-7272-581727<br/>
                                        E: info@mongemb.kz
                                    `
                },
                {
                    name : 'Kuwait',
                    title : 'Embassy in Kuwait',
                    description :   `
                                        K.Sairan El-Kuwait, South Surra, <br/>
                                        Al-Zahra Area, Block 8, Street 806, Villa 161 <br/>
                                        Tel: (+965) 25216551 / 25216554 <br/>
                                        E: kuwait@mfat.gov.mn monembkw@yahoo.com
                                    `
                },
                {
                    name : 'New York (USA)',
                    title : 'Permanent Mission to UN in New York',
                    description :   `
                                        6 East 77th street, New-York, N.Y.10021 USA <br/>
                                        Tel: 1-212-8619460 <br/>
                                        1-212-4726517<br/>
                                        1-212-7373874 <br/>
                                        E:mongolianmission@nyc.rr.com <br/>
                                        E: mongolia@un.int<br/>
                                        www.un.int/mongolia
                                    `
                },
                {
                    name : 'Poland',
                    title : 'Embassy in Warsaw',
                    description :   `
                                        Ambasada Mongolii 02516 Warszawa, <br/>
                                        Polska ul. Rejtana 15 m. 16 <br/>
                                        Tel: 48-22-849-9391 F<br/>
                                        E: mongamb@ikp.atm.com.pl <br/>
                                        www.ambasadamongolii.pl
                                    `
                },
                {
                    name : 'Russia',
                    title : 'Embassy in Moscow',
                    description :   `
                                        Россия г.Москва 121069 Борисоглебский пер.-11 <br/>
                                        Посольство Монгoлии <br/>
                                        Tel: 7-495-690-67-92 7-495-244-78-67 <br/>
                                        7-495-695-43-70<br/>
                                        7-495-2021353 F<br/>
                                        E: mongolia@online.ru<br/>
                                        www.mongolianembassy.ru
                                    `
                },
                {
                    name : 'Singapore',
                    title : 'Embassy in Singapore',
                    description :   `
                                        Embassy of Mongolia <br/>
                                        600 North Bridge Road. <br/>
                                        #24-08 Parkview Square Singapore 188778.<br/>
                                        Tel: 65-63480745<br/>
                                        E: consulmn@singnet.com.sg
                                    `
                },
                {
                    name : 'South Korea',
                    title : 'Embassy in Seoul',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        33-5 Hannam-dong, Yongsan-gu, <br/>
                                        Seoul 140-210, Republic of Korea <br/>
                                        Tel: 82-2-794-1350<br/>
                                        82-2-794-1951<br/>
                                        82-2-798-3464<br/>
                                        82-2-749-1990<br/>
                                        E: mongol5@kornet.net <br/>
                                        www.mongolembassy.com
                                    `
                },
                {
                    name : 'Sweden',
                    title : 'Embassy in Stockholm',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        Svärdvägen 25B 182 33 Danderyd Stockholm, Sweden <br/>
                                        Tel: (46-8) 753 11 35<br/>
                                        (46-8) 753 11 36 <br/>
                                        E: stockholm@mfat.gov.mn www.stockholm.mfat.gov.mn
                                    `
                },
                {
                    name : 'Switzerland',
                    title : 'Permanent Mission in Geneva',
                    description : `
                                        Permanent Representative  <br/>
                                        Permanente de la Mongolie 4, <br/>
                                        Chemin des Mollies 1293 Bellevue Suisse<br/>
                                        Tel: 41-22-7741974 <br/>
                                        E: mongolie@bluewin.ch<br/>
                                        www.missionmongolia.ch
                                        `
                },
                {
                    name : 'Thailand',
                    title : 'Embassy in Bangkok',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        100/3 Soi Ekkamai 22, Sukhumvit 63 Road, <br/>
                                        Klongton Nua, Wattana, Bangkok 10110 <br/>
                                        Tel: 66 2-381-1400, <br/>
                                        02-392-1011 <br/>
                                        E: mongemb@loxinfo.co.th
                                    `
                },
                {
                    name : 'Turkey',
                    title : 'Embassy in Ankara',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        A.Fethi Okyar sokak No.4, Oran, <br/>
                                        Cankaya 06700 Ankara, Turkey <br/>
                                        Tel: 90-312-4921028, <br/>
                                        90-312-4921027 (консул), <br/>
                                        E: mogolelc@yahoo.com
                                    `
                },
                {
                    name : 'USA',
                    title : 'Embassy in Washington',
                    description :   `
                                        Embassy of Mongolia<br/>
                                        2833 M Street, NW Washington DC 20007 USA<br/>
                                        Tel: 1-202-333-7117 <br/>
                                        E: esyam@mongolianembassy.us <br/>
                                        www.mongolianembassy.us
                                    `
                },
                {
                    name : 'Viet Nam',
                    title : 'Embassy in Hanoi',
                    description :   `
                                        Embassy of Mongolia <br/>
                                        Villa No 6, Van Phuc Diplomatic Quarter, Hanoi, Vietnam <br/>
                                        Tel: 84-4-384 53009<br/>
                                        E: mongembhanoi@vnn.vn<br/>
                                        www.mongembhanoi.com
                                    `
                },
                
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    #Embassy {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff;
        width: 100%;
        z-index: 15;

        .close {
            cursor: pointer;
            position: fixed;
            top: 10px;
            right: 10px;
            z-index: 16;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            padding: 60px 60px 0;
            .box {
                width: 25%;
                padding:0px 20px 60px;
                
                .title-box {
                    font-size: 20px;
                    font-weight: bold;
                }

                .detail-address {
                    padding: 10px;
                    background-color: #FAFAFA;
                    .description {
                        word-wrap: break-word;
                    }
                }
            }
        }

        @media only screen and (min-width: 1920px) {
            .list {
                padding: 60px 135px 0;
            }
        }
    }
</style>


